import React from 'react'
import { lazyRoundInputStyled } from '../common/RoundInputBox'
import { IFieldGroupProps } from '../FieldGroup'
import './index.scss'

export type CheckBoxesValues = { value: boolean, name: string, disabled?: boolean }[]

export interface ICheckBoxesFieldProps extends Omit<IFieldGroupProps, 'children'> {
  values: CheckBoxesValues
  placeholder?: string
  onChange: (e: CheckBoxesValues) => void
  disabled?: boolean
}

const Input = lazyRoundInputStyled<'input'>('input')


const CheckBoxesField = ({
  values,
  onChange
}: ICheckBoxesFieldProps) => {
  const handleChange = (value: boolean, name: string) => {
    onChange(values.map(val => {
      return val.name === name
        ? { value, name }
        : { ...val }
    }))
  }
  return (
    <div className='CheckBoxesField-Root'>
      {
        values.map(({ value, name, disabled }) => (
          <span className='CheckBoxesField-Root-Item' key={name}>
            <Input type='checkbox' checked={value} disabled={disabled} onChange={e => handleChange(e.currentTarget.checked, name)} />
            <label>{name}</label>
          </span>
        ))
      }
    </div>
  )
}

export default CheckBoxesField
