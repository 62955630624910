
// const BASE_URL = 'https://b7c13765-902f-46bd-abc5-2052e4a11722.mock.pstmn.io/api'
export const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://map.jsdc.com.tw/oldTree/api'

export const EndPoint = {
  Tree: {
    All: `${BASE_URL}/Tree/trees`,
    Query: `${BASE_URL}/Tree/query`,
    HealthStatus: `${BASE_URL}/Tree/healths`,
    AgeBound: `${BASE_URL}/Tree/ageBound`,
    Heightound: `${BASE_URL}/Tree/heightBound`,
  },
  Region: {
    Counties: `${BASE_URL}/Region/counties`,
    Towns: `${BASE_URL}/Region/towns`
  }
}
