import { uniqueId } from 'lodash'
import React, { useState } from 'react'
import WeatherCard from './WeatherCard'
import { CountyName, getTwoDays } from './src/api'
import Weather from './src/Weather'
import "./WeatherDialogContent.scss"
import icon from '../../../icon'
import FieldGroup from '../../UI/FieldGroup'
import SelectField, { ISelectFieldProps } from '../../UI/SelectField'
import Button from '../../UI/Button'
import useRegion from '../../../hooks/useRegion'
import RegionMap from '../../../hooks/useRegion/RegionMap'

type WeatherItem = {
  time: any
  description: any
  unit: any
  value: any
  temp: any
  ci: any
}

export interface IWeatherDialogContentProps {
  locations: Array<{ county: CountyName, town: string }>,
  token: string
  onSelectLocation?: (latlng: [number, number]) => void
}

const WeatherDialogContent: React.FC<IWeatherDialogContentProps> = ({
  locations,
  token,
  onSelectLocation = () => null
}: IWeatherDialogContentProps) => {
  const { counties: _counties, getTownsByCounty } = useRegion()
  const [county, setCounty] = useState('')
  const [town, setTown] = useState('')
  const counties = Object.keys(RegionMap).map(name => ({ name, value: name }))
  const [towns, setTowns] = useState<ISelectFieldProps['options']>([])
  const [weathers, setWeathers] = useState<WeatherItem[]>([])
  const [loading, setloading] = useState(false)
  const submitDisabled = !(town && county)

  const handleCountySelect = async (countyName: string) => {
    const towns = RegionMap[countyName]
    setTown('')
    setCounty(countyName)
    setTowns(towns?.map(town => ({ name: town, value: town })) || [])
  }

  const handleSubmit = async (county: string, town: string) => {
    setloading(true)
    try {
      const resp = await getTwoDays(county, town, token)
      const id = `${county}_${town}`
      const weather = new Weather(id, resp)
      const wx = weather.wx.getElements()
      const temp = weather.temp.getElements()
      const ci = weather.ci.getElements()
      const result: WeatherItem[] = wx.map((item, index) => ({
        ...item,
        temp: temp[index].description,
        ci: ci[index].value
      }))
      setWeathers(result)
      setloading(false)
      onSelectLocation([weather.location.lat, weather.location.lon])
    } catch (err) {
      alert('讀取天氣失敗')
    }
  }

  return (
    <div className="dui-WeatherDialogContent">
      <FieldGroup label='行政區'>
        <SelectField placeholder='縣市' value={county} options={counties} onChange={e => handleCountySelect(String(e.target.value))}/>
        <SelectField placeholder='鄉鎮' value={town} options={towns} onChange={e => setTown(String(e.target.value))}/>
      </FieldGroup>
      <Button disabled={submitDisabled} onClick={() => handleSubmit(county, town)}>搜尋</Button>
        {
          loading
            ? <img className="loading" src={icon.others.processing}  alt='source not found'/>
            : (
              <div className="weather-card-container">
                {
                  weathers.map((item, index) => (
                    <WeatherCard key={index}
                      title={item.time}
                      degree={item.temp}
                      description={item.description}
                      infoList={[item.ci]}
                      imgNum={item.value}/>
                  ))
                }
              </div>
            )
        }
    </div>
  )
}
WeatherDialogContent.displayName = 'WeatherDialogContent'
export default WeatherDialogContent
