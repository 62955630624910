import React from 'react'
import styled, { StyledComponent, ThemedStyledFunction } from 'styled-components'


export const style = `
  background-color: #e8e8e8;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
`

const RoundInputBox = styled.div`
  ${style}
`

export function lazyRoundInputStyled <P extends keyof JSX.IntrinsicElements>(elemType: keyof JSX.IntrinsicElements) {
  const factory = styled[elemType] as unknown as ThemedStyledFunction<P, any, {}, never>
  return factory`
    ${style}
  `
}

export default RoundInputBox
