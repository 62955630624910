import MenuItemWithDialog, { IMenuItemWithDialogProps } from '../MenuList/MenuItemWithDialog'
import React from 'react'
import TreeSearchDialogContent, { ITreeSearchDialogContentProps } from './TreeSearchDialogContent'
import Mappin from '../../Icons/Mappin'

export interface ITreeSearchMenuItemProps extends Omit<IMenuItemWithDialogProps, 'title' | 'Icon' | 'children'>, ITreeSearchDialogContentProps {

}

const TreeSearchMenuItem: React.FC<ITreeSearchMenuItemProps> = ({
  ...props
}: ITreeSearchMenuItemProps) => {
  return (
    <MenuItemWithDialog {...props} title='老樹搜尋' Icon={Mappin}>
      <TreeSearchDialogContent/>
    </MenuItemWithDialog>
  )
}
TreeSearchMenuItem.displayName = 'TreeSearchMenuItem'
export default TreeSearchMenuItem
