import React from 'react'
import './index.scss'

export interface ISwitchProps {
  checked: boolean
  onChange: () => void
}

const Switch = ({
  checked,
  onChange
}: ISwitchProps) => {
  return (
    <div className="Switch-Root" onClick={() => onChange()}>
      <input type="checkbox" checked={checked} onChange={() => onChange()}/>
      <span className="Switch-Root-Slider"></span>
    </div>
  )
}

export default Switch
