import React, { useState } from 'react'
import Button from '../../UI/Button'
import defaultThumbnail from '../../../assets/default-tree-thubnail.png'
import './index.scss'

export interface ITreeCardProps extends React.HTMLProps<HTMLDivElement> {
  title?: string
  region?: string
  location?: string
  thumbnail?: string
  kind?: string
  height?: string
  age?: string
  health?: string
  additionals?: { [k: string]: string }
  link?: string
}
const TreeCard: React.FC<Partial<ITreeCardProps>> = ({
  title = '...',
  region = '...',
  location = '...',
  thumbnail = '...',
  kind = '...',
  height = '...',
  health = '...',
  age = '...',
  link = '',
  additionals = {}
}) => {
  const [defaultThumb, setDefaultThumb] = useState<string>('')
  return (
    <div className='TreeCard-Root'>
      <div className="TreeCard-Root-Header">
        <img onError={() => setDefaultThumb(defaultThumbnail)} className="TreeCard-Root-Header-Thumb" src={defaultThumb || thumbnail} />
        <div className="TreeCard-Root-Header-Title">
          <span className="TreeCard-Root-Header-Title-Main">{title}</span>
          <span className="TreeCard-Root-Header-Title-Sub">{region}</span>
        </div>
      </div>
      <div className="TreeCard-Root-Info">
        <div className="TreeCard-Root-Info-Item">
          <span>所在地｜ </span>
          {location}
        </div>
        <div className="TreeCard-Root-Info-Item">
          <span>健康狀況｜ </span>
          {health}
        </div>
        <div className="TreeCard-Root-Info-Item">
          <span>樹種｜ </span>
          {kind}
        </div>
        <div className="TreeCard-Root-Info-Item">
          <span>推估樹齡｜ </span>
          {`${age} 年`}
        </div>
        <div className="TreeCard-Root-Info-Item">
          <span>樹高｜ </span>
          {`${height} 公尺`}
        </div>
      </div>
      {
        link && (
          <a href={link} target='_blank'>
            <Button className="TreeCard-Root-More">更多資訊</Button>
          </a>
        )
      }
    </div>
  )
}
TreeCard.displayName = 'TreeCard'
export default TreeCard
