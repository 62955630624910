import React from 'react'
import './index.scss'
import classNames from 'classnames'

export interface IFieldGroupProps {
  className?: string
  label?: string
  children: React.ReactNode
}

const FieldGroup = ({
  className,
  label,
  children
}: IFieldGroupProps) => {
  return (
    <div className={classNames('FieldGroup-Root', className)}>
      {label && <label>{label}</label>}
      {children}
    </div>
  )
}

export default FieldGroup
