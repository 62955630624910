import { omit, random, range, uniqueId } from "lodash"
import RegionMap from "../../../hooks/useRegion/RegionMap"
import { FeatureCollection, Feature, GeoJsonProperties, Geometry } from "geojson"
import JSDCGeoJSONLayer from "../../Layer/JSDCGeoJSONLayer"
import { useRef } from "react"
import Event from "../../utils/Event"
import { Icon, Marker } from "leaflet"
import treeIcon from '../../../assets/icon-tree.svg'
import { fetchAllTrees, fetchTreeAgeBound, fetchTreeHeightBound, fetchTreesByQuery, fetchTreesHealthStatus } from "./api"
import { TreeAllApi, TreeQueryApi } from "../../../apiConfigs/@types"


const mockPoints = (amount: number = 200): FeatureCollection => {
  const createRandomFeature = (): Feature => {
    const id = uniqueId()
    const counties = Object.keys(RegionMap)
    const county = counties[random(0, counties.length - 1)]
    const lng = random(121.4491, 121.6169, true)
    const lat = random(24.9521, 25.1369, true)
    return {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [ lng, lat]
      },
      "properties": {
        "oldtree_url":"",
        "tree_id": id,
        "tree_name": `tree_name_${id}`,
        county,
        "township": RegionMap[county][random(0, RegionMap[county].length)],
        "village": "梅花里",
        "location": "中正區八德路一段55號",
        "owner": "全球人壽保險股份有限公司",
        "oldtree_photo_url": '',
        "health_sta": "良好",
        "tree_story": "",
        "remarks": "",
        "longitude_x": lng,
        "latitude_y": lat,
        "altitude": "",
        "tree_taxon": "",
        "tree_age": random(10, 100),
        "tree_bust": "3.39",
        "tree_heigh": random(10, 50),
        "tree_crown": "18.3",
        "event_date": "2022",
        "source_id": "1",
        "source": "https://culture.gov.taipei/Content_List.aspx?n=15710846EFD86E1C",
        "trfc_templ": ""
      }
    }
  }
  const features = range(amount).map(() => createRandomFeature())
  return {
    "type": "FeatureCollection",
    "features": features
  }
}

const treesResponseToGeojson = (rawTrees: TreeAllApi['Response']): FeatureCollection => {
  const features: Feature[] = rawTrees.map(tree => ({
    "type": 'Feature',
    "geometry": tree.coordinate as Geometry,
    "properties": omit(tree, ['coordinate'])
  }))
  return {
    "type": "FeatureCollection",
    "features": features
  }
}


export type UseTreeQueryOptions = {
  treeClickEvent: Event<GeoJsonProperties>,
  treeCreateEvent: Event<JSDCGeoJSONLayer>, 
  treeRemoveEvent: Event<JSDCGeoJSONLayer>
}

const useTreeQuery = ({
  treeClickEvent, treeCreateEvent, treeRemoveEvent
}: UseTreeQueryOptions) => {
  const treesLayer = useRef<JSDCGeoJSONLayer>(new JSDCGeoJSONLayer({ id: 'tree', description: { name: 'tree', type: 'point' } })).current

  const registerLayerClick = (jsdcLayer: JSDCGeoJSONLayer) => {
    jsdcLayer.forEachLayerAsGeoJSON<Marker, {[k: string]: any}>((layer, properties) => {
      layer.setIcon(new Icon({ iconUrl: treeIcon, iconSize: [30, 40] }))
      layer.on('click', async () => {
        treeClickEvent.raise(properties)
      })
    })
  }

  const clearTrees = () => {
    treeRemoveEvent.raise(treesLayer)
    treesLayer.instance?.getLayers().forEach(layer => treesLayer.instance?.removeLayer(layer))
  }

  const getAllTrees = async () => {
    // const geojsonObject = mockPoints()
    const geojsonObject = treesResponseToGeojson(await fetchAllTrees())
    treesLayer.addFeature(geojsonObject)
    registerLayerClick(treesLayer)
    treeCreateEvent.raise(treesLayer)
    return treesLayer
  }

  const queryTrees = async (options: TreeQueryApi['requestBody']) => {
    // const geojsonObject = mockPoints(random(30, 70))
    const geojsonObject = treesResponseToGeojson(await fetchTreesByQuery(options))
    treesLayer.addFeature(geojsonObject)
    registerLayerClick(treesLayer)
    treeCreateEvent.raise(treesLayer)
    return treesLayer
  }

  const getTreesHealthStatus = async () => {
    return await fetchTreesHealthStatus()
  }

  const getTreeAgeBound = async () => {
    return await fetchTreeAgeBound()
  }

  const getTreeHeightBound = async () => {
    return await fetchTreeHeightBound()
  }

  return {
    treesLayer,
    getAllTrees,
    queryTrees,
    clearTrees,
    getTreesHealthStatus,
    getTreeAgeBound,
    getTreeHeightBound
  }
}

export default useTreeQuery
