import { EndPoint } from "../../../apiConfigs"
import { TreeAgeBoundApi, TreeAllApi, TreeHealthStatusApi, TreeHeightBoundApi, TreeQueryApi } from "../../../apiConfigs/@types"

export const fetchAllTrees = async () => {
  const resp = await fetch(EndPoint.Tree.All)
  return await resp.json() as TreeAllApi['Response']
}

export const fetchTreesByQuery = async ({
  keyword,
  taxon,
  health,
  age_gt,
  age_lt,
  height_gt,
  height_lt,
  county_code,
  town_code,
  tree_phenology
}: TreeQueryApi['requestBody']) => {
  const headers = new Headers()
  headers.append("Content-Type", "application/x-www-form-urlencoded")

  const urlencoded = new URLSearchParams()
  keyword && urlencoded.append("keyword", keyword)
  taxon && urlencoded.append("taxon", taxon)
  health && urlencoded.append("health", health)
  age_gt && urlencoded.append("age_gt", String(age_gt))
  age_lt && urlencoded.append("age_lt", String(age_lt))
  height_gt && urlencoded.append("height_gt", String(height_gt))
  height_lt && urlencoded.append("height_lt", String(height_lt))
  county_code && urlencoded.append("county_code", county_code)
  town_code && urlencoded.append("town_code", town_code)
  tree_phenology && urlencoded.append("tree_phenology", `[${tree_phenology.map(e => `'${e}'`).join()}]`);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: headers,
    body: urlencoded
  }

  const resp = await fetch(EndPoint.Tree.Query,requestOptions)
  return await resp.json() as TreeQueryApi['Response']
}

export const fetchTreesHealthStatus = async () => {
  const resp = await fetch(EndPoint.Tree.HealthStatus)
  return await resp.json() as TreeHealthStatusApi['Response']
}

export const fetchTreeAgeBound = async () => {
  const resp = await fetch(EndPoint.Tree.AgeBound)
  return await resp.json() as TreeAgeBoundApi['Response']
}

export const fetchTreeHeightBound = async () => {
  const resp = await fetch(EndPoint.Tree.Heightound)
  return await resp.json() as TreeHeightBoundApi['Response']
}
