import React, { useContext, useEffect, useState } from 'react'
import './index.scss'
import TextField from '../../UI/TextField'
import FieldGroup from '../../UI/FieldGroup'
import SelectField, { ISelectFieldProps } from '../../UI/SelectField'
import Button from '../../UI/Button'
import useRegion from '../../../hooks/useRegion'
import { JSDCContext } from '../../../JSDC/Context'
import { Slider } from '@mui/material'
import { TreeAllApi, TreeQueryApi } from '../../../apiConfigs/@types'
import { FeatureLayer } from '../../../JSDC/Layer/JSDCLayer'
import { mapValues } from 'lodash'
import CheckBoxDropDownField, { ICheckBoxesFieldProps, CheckBoxesValues } from '../../UI/CheckBoxesField'


export interface ITreeSearchDialogContentProps {
}

const defaultPhenologies: CheckBoxesValues = [
  { value: false, name: '開花' },
  { value: false, name: '結實' },
  { value: false, name: '新葉/芽' },
  { value: false, name: '其他' },
]

const TreeSearchDialogContent: React.FC<ITreeSearchDialogContentProps> = ({ }: ITreeSearchDialogContentProps) => {
  const { treeQuery, treeCreateEvent, Jsdc } = useContext(JSDCContext)
  const { counties: _counties, getTownsByCounty } = useRegion()
  const [ageBound, setAgeBound] = useState<{ min: number, max: number }>({ min: 10, max: 100 })
  const [heightBound, setHeightBound] = useState<{ min: number, max: number }>({ min: 10, max: 100 })
  const [keyword, setKeyword] = useState('')
  const [treeKind, setTreeKind] = useState('')
  const [health, setHealth] = useState('')
  const [phenologies, setPhenologies] = useState<CheckBoxesValues>(defaultPhenologies)
  const [height, setHeight] = useState<number[]>([ageBound.min, ageBound.max])
  const [age, setAge] = useState<number[]>([heightBound.min, heightBound.max])
  const [county, setCounty] = useState('')
  const [town, setTown] = useState('')
  const [healthStatus, setHealthStatus] = useState<ISelectFieldProps['options']>([])
  const counties = _counties.map(({ countyname, countycode }) => ({ name: countyname, value: countycode }))
  const [towns, setTowns] = useState<ISelectFieldProps['options']>([])
  const [isLoading, serIsLoading] = useState(false)

  const validToSearch = () => {
    const atLeastOneInput = !!(keyword || treeKind || health || county || town)
    return atLeastOneInput
  }

  const handleCountySelect = async (countyCode: string) => {
    setTown('')
    const towns = await getTownsByCounty({ countyCode })
    setCounty(countyCode)
    setTowns(towns?.map(({ towncode, townname }) => ({ name: townname, value: towncode })) || [])
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    treeQuery.clearTrees()
    const [age_gt, age_lt] = age
    const [height_gt, height_lt] = height
    const tree_phenologies = phenologies.filter(item => item.value).map(item => item.name)
    const queryOptions: TreeQueryApi['requestBody'] = {
      keyword,
      taxon: treeKind,
      health,
      age_gt,
      age_lt,
      height_gt,
      height_lt,
      county_code: county,
      town_code: town,
      tree_phenology: tree_phenologies.length === 0 ? undefined : tree_phenologies
    }
    serIsLoading(true)
    const treesLayer = await treeQuery.queryTrees(queryOptions)
    const viewer = await Jsdc.asyncViewer
    const trees = treesLayer.instance?.getLayers()
    trees && trees.length > 0 && treesLayer.instance && viewer.flyToBounds(treesLayer.instance?.getBounds())
    serIsLoading(false)
  }

  const handleClear = async () => {
    treeQuery.clearTrees()
    setKeyword('')
    setTreeKind('')
    setHealth('')
    setCounty('')
    setTown('')
    setAge([ageBound.min, ageBound.max])
    setHeight([heightBound.min, heightBound.max])
    setPhenologies(defaultPhenologies)
  }

  const treesCount = treeQuery.treesLayer.instance?.getLayers().length

  useEffect(() => {
    (async () => {
      const ageBound = mapValues((await treeQuery.getTreeAgeBound()), (value) => Number(value))
      const heightBound = mapValues((await treeQuery.getTreeHeightBound()), (value) => Number(value))
      const healthStatus = await treeQuery.getTreesHealthStatus()
      const healthSatusOptions = healthStatus.map(({ health_sta }) => ({ name: health_sta, value: health_sta }))
      setHealthStatus(healthSatusOptions)
      setAgeBound(ageBound)
      setHeightBound(heightBound)
      setAge([ageBound.min, ageBound.max])
      setHeight([heightBound.min, heightBound.max])
    })()
  }, [])
  return (
    <div className="dui-TreeSearchDialogContent">
      <form className="dui-TreeSearchDialogContent-form" onSubmit={handleSubmit}>
        <TextField
          disabled={isLoading}
          label='關鍵字'
          placeholder='關鍵字找老樹'
          value={keyword}
          onChange={e => setKeyword(e.target.value)} />

        <TextField
          disabled={isLoading}
          label='樹種'
          placeholder='樹種找老樹'
          value={treeKind}
          onChange={e => setTreeKind(e.target.value)} />

        <SelectField disabled={isLoading} label='健康狀況' value={health} options={healthStatus} onChange={e => setHealth(String(e.target.value))} />

        <FieldGroup label='物候'>
          <CheckBoxDropDownField values={phenologies} onChange={e => setPhenologies(e)} />
        </FieldGroup>

        <FieldGroup label='行政區'>
          <SelectField disabled={isLoading} placeholder='縣市' value={county} options={counties} onChange={e => handleCountySelect(String(e.target.value))} />
          <SelectField disabled={isLoading} placeholder='鄉鎮' value={town} options={towns} onChange={e => setTown(String(e.target.value))} />
        </FieldGroup>

        <FieldGroup label={`樹齡 (${age[0]}y ~ ${age[1]}y)`}>
          <div style={{ margin: '0 20px' }}>
            <Slider
              disabled={isLoading}
              min={ageBound.min}
              max={ageBound.max}
              value={age}
              onChange={(_, value) => setAge(value as number[])}
              valueLabelDisplay='auto'
              getAriaValueText={(e) => String(e)} />
          </div>
        </FieldGroup>

        <FieldGroup label={`樹高 (${height[0]}m ~ ${height[1]}m)`}>
          <div style={{ margin: '0 20px' }}>
            <Slider
              disabled={isLoading}
              min={heightBound.min}
              max={heightBound.max}
              value={height}
              onChange={(_, value) => setHeight(value as number[])}
              valueLabelDisplay='auto'
              getAriaValueText={(e) => String(e)} />
          </div>
        </FieldGroup>

        <Button disabled={!validToSearch() || isLoading}>搜尋{isLoading && ' loading...'}</Button>
        <Button type='button' onClick={handleClear}>清除</Button>
      </form>
      {
        Number(treesCount) > 0 && (
          <div className='dui-TreeSearchDialogContent-result'>
            <span className='dui-TreeSearchDialogContent-result-title'>檢索結果</span>
            <span className='dui-TreeSearchDialogContent-result-number'>{treesCount}</span>
          </div>
        )
      }
    </div>
  )
}
TreeSearchDialogContent.displayName = 'TreeSearchDialogContent'
export default TreeSearchDialogContent
