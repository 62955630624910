import MenuItemWithDialog, { IMenuItemWithDialogProps } from '../MenuList/MenuItemWithDialog'
import icon from '../../../icon'
import React from 'react'
import ToolSettingDialogContent, { IToolSettingDialogContentProps } from './ToolSettingDialogContent'
import Setting from '../../Icons/Setting'

export interface IToolSettingMenuItemProps extends Omit<IMenuItemWithDialogProps, 'title' | 'Icon' | 'children'>, IToolSettingDialogContentProps {

}

const ToolSettingMenuItem: React.FC<IToolSettingMenuItemProps> = (props: IToolSettingMenuItemProps) => {
  return (
    <MenuItemWithDialog {...props} title='工具設定' Icon={Setting}>
      <ToolSettingDialogContent />
    </MenuItemWithDialog>
  )
}
ToolSettingMenuItem.displayName = 'ToolSettingMenuItem'
export default ToolSettingMenuItem
