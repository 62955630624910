import icon from '../../../icon'
import { map } from 'lodash'
import React, { useContext, useState } from 'react'
import './index.scss'
import Switch from '../../UI/Switch'
import { JSDCContext } from '../../../JSDC/Context'
import { Marker } from 'leaflet'
import Button from '../../UI/Button'
import { DuiContext } from '../../Context'

export interface IToolSettingDialogContentProps {
}

const ToolSettingDialogContent: React.FC<IToolSettingDialogContentProps> = ({
}: IToolSettingDialogContentProps) => {
  const { treeQuery, toolSetting, setToolSetting, clusterControll, heatMapControll } = useContext(JSDCContext)
  const dui = useContext(DuiContext)

  const handleClusterChange = (clusterEnable: boolean) => {
    setToolSetting(prev => ({ ...prev, cluster: clusterEnable }))
    clusterEnable
      ? clusterControll.setShow()
      : clusterControll.setHide()
    if (!clusterEnable && toolSetting.hotspot) {
      treeQuery.treesLayer.forEachLayerAsGeoJSON<Marker>(layer => {
        layer.setOpacity(0)
      })
    }
  }
  const handleHotspotChange = (hotSpotEnable: boolean) => {
    setToolSetting(prev => ({ ...prev, hotspot: hotSpotEnable }))
    hotSpotEnable
      ? heatMapControll.setShow()
      : heatMapControll.setHide()
    if (hotSpotEnable && !toolSetting.cluster) {
      treeQuery.treesLayer.forEachLayerAsGeoJSON<Marker>(layer => {
        layer.setOpacity(0)
      })
    }
    if (!hotSpotEnable) {
      treeQuery.treesLayer.forEachLayerAsGeoJSON<Marker>(layer => {
        layer.setOpacity(1)
      })
    }
  }

  const shouldGoToSearchTreePanel = () => {
    const length = treeQuery.treesLayer.instance?.getLayers().length
    return !length
  }

  const handleGoSearchTreePanel = () => {
    dui.menuSwitch('老樹搜尋')
  }
  return (
    <div className="dui-ToolSettingDialogContent">
      <div className="dui-ToolSettingDialogContent-ToolSet">
        <div className="dui-ToolSettingDialogContent-ToolSet-Tool">
          <label>熱區 Hotspot</label>
          <Switch checked={toolSetting.hotspot} onChange={() => handleHotspotChange(!toolSetting.hotspot)}/>
        </div>
      </div>
      {
        shouldGoToSearchTreePanel() && (
          <Button onClick={handleGoSearchTreePanel}>請先搜尋老樹</Button>
        )
      }
    </div>
  )
}
ToolSettingDialogContent.displayName = 'ToolSettingDialogContent'
export default ToolSettingDialogContent
