import JSDC from "../";
import { LayerInfo } from "../Controller/LayerController";
import useLayerInfos from "../hooks/useLayerInfos";
import React, { createContext, useEffect, useRef, useState } from "react";
import useTreeQuery from "../hooks/useTreeQuery";
import Event from "../utils/Event";
import { GeoJsonProperties } from "geojson"
import useCluster from "../hooks/layerVisualization/useCluster";
import useHeatMap from "../hooks/layerVisualization/useHeatMap";
import JSDCGeoJSONLayer from "../Layer/JSDCGeoJSONLayer";
import { Marker } from "leaflet";

export type ToolSettingState = { cluster: boolean, hotspot: boolean }

export type JSDCContextType = {
  Jsdc: JSDC
  layerInfos: LayerInfo[]
  treeQuery: ReturnType<typeof useTreeQuery>
  treeClickEvent: Event<GeoJsonProperties>
  treeCreateEvent: Event<JSDCGeoJSONLayer>
  toolSetting: ToolSettingState
  setToolSetting: React.Dispatch<React.SetStateAction<ToolSettingState>>
  clusterControll: ReturnType<typeof useCluster>
  heatMapControll: ReturnType<typeof useHeatMap>
}

const JSDCContext = createContext<JSDCContextType>({} as JSDCContextType)

export interface IJSDCProviderProps {
  children: React.ReactNode
  Jsdc: JSDC
}

const JSDCProvider: React.FC<IJSDCProviderProps> = ({
  Jsdc,
  children
}) => {
  const treeClickEvent = useRef(new Event<GeoJsonProperties>()).current
  const treeCreateEvent = useRef(new Event<JSDCGeoJSONLayer>()).current
  const treeRemoveEvent = useRef(new Event<JSDCGeoJSONLayer>()).current
  const [ toolSetting, setToolSetting ] = useState<ToolSettingState>({ cluster: true, hotspot: false })
  const { layerInfos } = useLayerInfos(Jsdc)
  const treeQuery = useTreeQuery({ treeClickEvent, treeCreateEvent, treeRemoveEvent })
  const clusterControll = useCluster(Jsdc.asyncViewer)
  const heatMapControll = useHeatMap(Jsdc.asyncViewer)

  const getToolSetting = () => new Promise<ToolSettingState>((resolve) => {
    setToolSetting(prev => {
      resolve(prev)
      return prev
    })
  })

  useEffect(() => {
    treeQuery.getAllTrees()
    clusterControll.attatch(treeQuery.treesLayer)
    heatMapControll.attatch(treeQuery.treesLayer)
    treeCreateEvent.addEventListener(async (layer) => {
      const toolSetting = await getToolSetting()
      if (!layer) return
      toolSetting.hotspot ? heatMapControll.setShow() : heatMapControll.setHide()
      const shouldHideTreeIcon = toolSetting.hotspot && !toolSetting.cluster
      shouldHideTreeIcon
        ? treeQuery.treesLayer.forEachLayerAsGeoJSON<Marker>(layer => layer.setOpacity(0))
        : treeQuery.treesLayer.forEachLayerAsGeoJSON<Marker>(layer => layer.setOpacity(1))
    })
  }, [])

  const value = {
    Jsdc,
    layerInfos,
    treeQuery,
    treeClickEvent,
    treeCreateEvent,
    toolSetting,
    setToolSetting,
    clusterControll,
    heatMapControll
  }
  return (
    <JSDCContext.Provider value={value}>
      {children}
    </JSDCContext.Provider>
  )
}
JSDCProvider.displayName = 'JSDCProvider'
export {
  JSDCContext,
  JSDCProvider
}
