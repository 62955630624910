import React from 'react'
import { IActivableProps } from './types'
import { inactiveColor, activeColor, activeAccentColor } from './common/ActivableColor'

const Weather = ({ active }: IActivableProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 32 32" width="32">
      <clipPath id="a">
        <path d="m-105.583 436.35.224 1.938 1.376 1.55 4.738-1.638 4.441 5.043.516 1.411 1.228.659a7.322 7.322 0 0 0 2.451-5.357 19.161 19.161 0 0 0 -1.511-6.493 14.331 14.331 0 0 0 -5.945-1.12c-3.525 0-8.953 1.971-8.953 1.971z" />
      </clipPath>
      <g transform="translate(122.211 -428.079)">
        <path style={{ fill: active ? activeAccentColor : inactiveColor }} d="m-116.028 455.816h19.637a5.788 5.788 0 0 0 5.781-5.781 5.777 5.777 0 0 0 -2.45-4.722 7.454 7.454 0 0 0 2.451-5.516 7.464 7.464 0 0 0 -7.456-7.454 7.48 7.48 0 0 0 -7.294 5.945 6.872 6.872 0 0 0 -4.43-1.629 6.9 6.9 0 0 0 -6.889 6.889 6.784 6.784 0 0 0 .041.737 5.79 5.79 0 0 0 -5.172 5.75 5.788 5.788 0 0 0 5.781 5.781zm17.963-22.173a6.162 6.162 0 0 1 6.156 6.154 6.159 6.159 0 0 1 -2.379 4.857l-.045-.016c-.11-.042-.221-.081-.334-.116-.061-.019-.121-.036-.182-.053-.1-.027-.189-.052-.286-.074s-.184-.037-.277-.053c-.073-.012-.146-.027-.22-.037-.172-.023-.346-.039-.521-.047a6.71 6.71 0 0 0 -.377-.842 6.9 6.9 0 0 0 -7.453-3.578c-.035-.055-.079-.1-.116-.157s-.071-.106-.111-.16a6.173 6.173 0 0 1 6.145-5.878zm-17.273 11.957h.091a.581.581 0 0 0 .483-.221.607.607 0 0 0 .124-.517 5.225 5.225 0 0 1 -.129-1.14 5.221 5.221 0 0 1 5.216-5.215 5.209 5.209 0 0 1 4.109 2.016l.005.009c.075.1.138.186.2.276.091.135.183.27.263.415l.013.021a.605.605 0 0 0 .694.3 5.219 5.219 0 0 1 6.414 3.621.6.6 0 0 0 .612.444l.093-.006c.082-.005.163 0 .245 0a4.157 4.157 0 0 1 .946.142c.066.019.133.034.2.057.033.011.065.024.1.036.129.047.257.1.382.157a4.182 4.182 0 0 1 2.409 3.782 4.186 4.186 0 0 1 -4.181 4.181h-18.323a4.186 4.186 0 0 1 -4.182-4.181 4.191 4.191 0 0 1 4.216-4.177z" />
        <g clipPath="url(#a)">
          <path style={{ fill: active ? activeColor : inactiveColor }} d="m-116.028 455.816h19.637a5.788 5.788 0 0 0 5.781-5.781 5.777 5.777 0 0 0 -2.45-4.722 7.454 7.454 0 0 0 2.451-5.516 7.464 7.464 0 0 0 -7.456-7.454 7.48 7.48 0 0 0 -7.294 5.945 6.872 6.872 0 0 0 -4.43-1.629 6.9 6.9 0 0 0 -6.889 6.889 6.784 6.784 0 0 0 .041.737 5.79 5.79 0 0 0 -5.172 5.75 5.788 5.788 0 0 0 5.781 5.781zm17.963-22.173a6.162 6.162 0 0 1 6.156 6.154 6.159 6.159 0 0 1 -2.379 4.857l-.045-.016c-.11-.042-.221-.081-.334-.116-.061-.019-.121-.036-.182-.053-.1-.027-.189-.052-.286-.074s-.184-.037-.277-.053c-.073-.012-.146-.027-.22-.037-.172-.023-.346-.039-.521-.047a6.71 6.71 0 0 0 -.377-.842 6.9 6.9 0 0 0 -7.453-3.578c-.035-.055-.079-.1-.116-.157s-.071-.106-.111-.16a6.173 6.173 0 0 1 6.145-5.878zm-17.273 11.957h.091a.581.581 0 0 0 .483-.221.607.607 0 0 0 .124-.517 5.225 5.225 0 0 1 -.129-1.14 5.221 5.221 0 0 1 5.216-5.215 5.209 5.209 0 0 1 4.109 2.016l.005.009c.075.1.138.186.2.276.091.135.183.27.263.415l.013.021a.605.605 0 0 0 .694.3 5.219 5.219 0 0 1 6.414 3.621.6.6 0 0 0 .612.444l.093-.006c.082-.005.163 0 .245 0a4.157 4.157 0 0 1 .946.142c.066.019.133.034.2.057.033.011.065.024.1.036.129.047.257.1.382.157a4.182 4.182 0 0 1 2.409 3.782 4.186 4.186 0 0 1 -4.181 4.181h-18.323a4.186 4.186 0 0 1 -4.182-4.181 4.191 4.191 0 0 1 4.216-4.177z" />
        </g>
      </g>
      <path d="m0 0h32v32h-32z" fill="none" />
    </svg>
  )
}

export default Weather
