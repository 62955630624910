import headerDImgSrc from './assets/key-art.svg'
import headerMBImgSrc from './assets/key-art-m.jpg'
import aboutWalkImgSrc from './assets/intro-photo.png'
import React, { useContext, useEffect, useState } from 'react';
import { JSDCContext, JSDCProvider } from './JSDC/Context'
import JSDC from './JSDC';
import Leaflet, { latLng, latLngBounds, Marker } from 'leaflet'
import { IDuiContextProviderProps, DuiContextProvider } from './components/Context';
import DguideWalksApp from './components/DguideWalksApp';
import { baseUrl } from './icon';
import { DguidewalksProvider } from './JSDC/Dguidewalks/Context';
import { GeoJsonProperties } from 'geojson'
import ResponsiveDialog from './components/ResponsiveDialog';
import TreeCard, { ITreeCardProps } from './components/LeafletPopup/TreeCard';
import { get } from 'lodash';

const EVENT_ID = 'n0008'

const aboutWalkContent = `
<h3 style="text-align: center; color: var(--dui-bg-accent);">發掘在台灣各地的老樹</h3>
老樹承載了許多民眾的成長記憶與點滴，民眾也可提供老樹的生活故事，或樹神信仰文化等連結，增添老樹的生命故事豐富性。
</br>
</br>
老樹報報網站彙整全國現有已知老樹資料，以公開而方便的平臺供全民取用，藉由拋磚引玉的方式邀請全民參與，提供老樹的第一手資訊，甚至補充老樹資料庫的內容。
</br>
</br>
希望能透過全民參與的方式，提升民眾愛樹保樹的風氣，並回饋給老樹管理單位及時的資訊，以保護全國的珍貴老樹。
</br>
`

const credit = `本平臺由老樹報報團隊與智紳數位文化事業有限公司共同建置，並提供全國民眾使用，若有其他利用或授權需求請洽老樹報報團隊。`

const duiConfigProps: IDuiContextProviderProps = {
  sidebarTitle: '',
  sidebarSubtitle: '',
  aboutWalkImgSrc,
  aboutWalkContent,
  credit,
  creditHref: 'https://www.facebook.com/JRSHENDigitalCulture/',
  headerMBImgSrc,
  headerDImgSrc,
  menuSwitchItems: [{ id: '數位集章', name: '數位集章' }],
  weatherConfig: {
    token: 'CWB-232A270E-12F1-4381-B9F2-DF2D2670A077',
    locations: [
      { county: '新北市', town: '三峽區' },
      { county: '新北市', town: '新店區' },
      { county: '臺北市', town: '文山區' }
    ]
  },
  themeConfig: {
    '--dui-primary': '#35923F',
    '--dui-accent': '#EAA625',
    '--dui-secondary': '#35BFA3',
    '--dui-bg-secondary': '#FFC824',
    '--dui-bg-accent': '#35923F'
  }
}

type ForExactLayerNameOptions = {
  Jsdc: JSDC
  layerName: string
  featureName: string
}

function forExactLayerName<L extends Marker,> ({ Jsdc, layerName, featureName }: ForExactLayerNameOptions, cb: (layer: L) => void) {
  const targetFeature = Jsdc.Controller.get('Layer').getByName(layerName)?.isGeoJSON()
  if (!targetFeature) return
  const layers = targetFeature.instance.getLayers() as L[]
  for (const layer of layers) {
    const layerName = layer.feature?.properties.name
    if (!layerName) continue
    if (featureName.includes(String(layerName))) {
      cb(layer)
      break
    }
  }
}

const getPOIIcon = (type: string) => {
  return Leaflet.icon({ iconUrl: `${baseUrl}map_icons/type${type}.svg`, iconSize: [30, 40] })
}

function App () {
  const { Jsdc, treeQuery, treeClickEvent } = useContext(JSDCContext)
  const [openCard, setOpenCard] = useState(false)
  const [cardProps, setCardProps] = useState<ITreeCardProps>({})

  const addTreesLayerToControl = async () => {
    await Jsdc.asyncViewer
    const layerController = Jsdc.Controller.get('Layer')
    const layer = treeQuery.treesLayer
    layerController.add(layer, { hidden: true, addToMap: true })
  }

  const handleTreeClick = (properties?: GeoJsonProperties) => {
    setCardProps({
      title: get(properties, 'treeName', '未命名'),
      region: `${get(properties, 'county', '')}${get(properties, 'township', '')}`,
      location: get(properties, 'location', '未知'),
      thumbnail: get(properties, 'oldtree_photo_url'),
      health: get(properties, 'health_sta', '未知'),
      kind: get(properties, 'tree_taxon_name') || get(properties, 'tree_taxon_family'),
      height: get(properties, 'tree_heigh', '未知'),
      age: get(properties, 'tree_age', '未知'),
      link: get(properties, 'oldtreeUrl') || get(properties, 'source'),
      additionals: {}
    })
    setOpenCard(true)
  }

  useEffect(() => {
    addTreesLayerToControl()
    treeClickEvent.addEventListener(handleTreeClick)
    const oldHeaderImgElem = document.querySelector('#root > div > div > div.pd-space > div.dui-MenuList > div.header-img') as HTMLElement
    const headerImgElem= document.createElement('a')
    headerImgElem.className = oldHeaderImgElem?.className
    headerImgElem.href = 'https://oldtree.life/'
    headerImgElem.target = '_blank'
    oldHeaderImgElem?.parentElement?.insertBefore(headerImgElem, oldHeaderImgElem)
    oldHeaderImgElem?.remove()
  }, [])

  return (
    <div className='root'>
      <DguideWalksApp/>
      <ResponsiveDialog className='Popup-Custom' open={openCard} onClose={() => setOpenCard(false)}>
        <TreeCard {...cardProps}/>
      </ResponsiveDialog>
    </div>
  )
}

const AppWrapper = () => {
  const [Jsdc] = useState(new JSDC(EVENT_ID, {
    bound: latLngBounds(
      latLng(21.882, 118.345),
      latLng(26.510, 122.014)
    ),
    maxZoom: 20
  }
  ))
  return (
    <JSDCProvider Jsdc={Jsdc}>
      <DguidewalksProvider
        Jsdc={Jsdc}
        layersHiddenFromUI={[]}
        layersShowOnMapByDefault={['臺灣通用電子地圖(等高線+門牌)']}
      >
        <DuiContextProvider
          {...duiConfigProps}
        >
          <App />
        </DuiContextProvider>
      </DguidewalksProvider>
    </JSDCProvider>
  )
}

export default AppWrapper