import { Map, MarkerClusterGroup, MarkerClusterGroupOptions } from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
import JSDCGeoJSONLayer from '../../Layer/JSDCGeoJSONLayer'
import JSDCMarkersLayer from '../../Layer/JSDCMarkersLayer'

export type UseClusterValidLayer = JSDCGeoJSONLayer | JSDCMarkersLayer

export type UseClusterParams = {
  layers?: Array<UseClusterValidLayer>
  config?: MarkerClusterGroupOptions
}

const useCluster = (
  asyncMap: Promise<Map>,
  options: UseClusterParams = {}
) => {
  const {
    layers = [],
    config = {
      showCoverageOnHover: false
    }
  } = options
  const cluster = useRef(new MarkerClusterGroup(config))
  const sourceLayers = useRef<{[k: string]: UseClusterValidLayer}>({})
  const [show, setShow] = useState(true)

  const showCluster = async () => {
    const map = await asyncMap
    map.removeLayer(cluster.current)
    map.addLayer(cluster.current)
    Object.values(sourceLayers.current).forEach(layer => layer.show = false)
    setShow(true)
  }

  const hideCluster = async () => {
    const map = await asyncMap
    map.removeLayer(cluster.current)
    Object.values(sourceLayers.current).forEach(layer => layer.show = true)
    setShow(false)
  }

  const toggleShowCluster = () => {
    if (show) {
      return hideCluster()
    }
    showCluster()
  }

  const hideSourceLayer = (layer: UseClusterValidLayer) => {
    if (!layer.instance) return
    layer.show = false
  }

  const attatch = (layer: UseClusterValidLayer) => {
    sourceLayers.current[layer.id] = layer
    if (!layer.instance) return
    cluster.current.addLayers(layer.instance.getLayers())
    hideSourceLayer(layer)
    layer.instance.addEventListener('layeradd', ({ layer }) => {
      cluster.current.addLayer(layer)
    })
    layer.instance.addEventListener('layerremove', ({ layer }) => {
      cluster.current.removeLayer(layer)
    })
  }

  useEffect(() => {
    (async function () {
      const map = await asyncMap
      layers.forEach(layer => attatch(layer))
      map.addLayer(cluster.current)
    })()
  }, [])

  return {
    show,
    attatch,
    toggleShowCluster,
    setShow: showCluster,
    setHide: hideCluster
  }
}

export default useCluster
