import React from 'react'
import './index.scss'
import RoundInput from '../common/RoundInputBox'
import FieldGroup, { IFieldGroupProps } from '../FieldGroup'
import { lazyRoundInputStyled } from '../common/RoundInputBox'

export interface ISelectFieldProps extends Omit<IFieldGroupProps, 'children'> {
  value: string | number
  placeholder?: string
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  options: { value: string | number, name: string }[]
  disabled?: boolean
}

const Select = lazyRoundInputStyled<'select'>('select')

const SelectField = ({
  value,
  placeholder,
  onChange,
  label,
  options,
  disabled = false
}: ISelectFieldProps) => {
  return (
    <FieldGroup className='SelectField-Root' label={label}>
      <div className='SelectField-Root-SelectWrapper'>
        <Select disabled={disabled} placeholder={placeholder} value={value} onChange={onChange}>
          <option value=''>{placeholder}</option>
          {
            options.map(({ value, name }) => (
              <option key={String(value)} value={String(value)}>{name}</option>
            ))
          }
        </Select>
      </div>
    </FieldGroup>
  )
}

export default SelectField
