import React from 'react'
import FieldGroup, { IFieldGroupProps } from '../FieldGroup'
import { lazyRoundInputStyled } from '../common/RoundInputBox'

export interface ITextFieldProps extends Omit<IFieldGroupProps, 'children'> {
  type?: React.HTMLInputTypeAttribute
  value: string | number
  placeholder?: string
  disabled?: boolean
  onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange']
}

const Input = lazyRoundInputStyled<'input'>('input')

const TextField = ({
  label,
  type = 'text',
  value,
  placeholder,
  disabled = false,
  onChange = () => null
}: ITextFieldProps) => {
  return (
    <FieldGroup label={label}>
      <Input disabled={disabled} type={type} placeholder={placeholder} onChange={onChange} value={value} />
    </FieldGroup>
  )
}

export default TextField
