import './style/index.scss'
import * as ReactDOMClient from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import React from 'react'
import App from './App'

ReactDOMClient.createRoot(document.getElementById('root') as Element)
  .render(
    <App />
)

reportWebVitals();
