import { useEffect, useState } from 'react'
import { EndPoint } from '../../apiConfigs'
import { RegionCountiesApi, RegionTownsApi } from '../../apiConfigs/@types'

const fetchCounties = async () => {
  const resp = await fetch(EndPoint.Region.Counties)
  return await resp.json() as RegionCountiesApi['Response']
}

const fetchTownsByCounty = async (options: RegionTownsApi['SearchParam']) => {
  const searchParam = new URLSearchParams(options)
  const resp = await fetch(`${EndPoint.Region.Towns}/?${searchParam}`)
  return await resp.json() as RegionTownsApi['Response']
}

const useRegion = () => {
  const [counties, setCounties] = useState<RegionCountiesApi['Response']>([])

  useEffect(() => {
    (async () => {
      const counties = await fetchCounties()
      setCounties(counties)
    })()
  }, [])
  return {
    counties,
    getTownsByCounty: fetchTownsByCounty
  }
}

export default useRegion
