import { TileLayer } from "leaflet"
import { uniqueId } from "lodash"
import JSDCLayer from "../Layer/JSDCLayer"
import Event from "../utils/Event"

export type DguidewalksOptions = {
  layerNameOrder?: string[]
}

export default class Dguidewalks {
  layerNameOrder: string[]
  gisDataLoadEvent = new Event()
  constructor (options: DguidewalksOptions) {
    this.layerNameOrder = options.layerNameOrder || []
  }

  async loadGisData (): Promise<JSDCLayer[]> {
    // implement data loading	
    const basemaps = [
      {
        name: '正射影像(混合)',
        url: 'https://wmts.nlsc.gov.tw/wmts/PHOTO_MIX/default/GoogleMapsCompatible/{z}/{y}/{x}'
      }, {
        name: '臺灣通用正射影像',
        url: 'https://wmts.nlsc.gov.tw/wmts/PHOTO2/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '臺灣通用電子地圖(灰階)',
        url: 'https://wmts.nlsc.gov.tw/wmts/EMAP01/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '臺灣通用電子地圖(等高線+門牌)',
        url: 'https://wmts.nlsc.gov.tw/wmts/EMAP5/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '臺灣堡圖（1904年）',
        url: 'https://gis.sinica.edu.tw/tileserver/file-exists.php?img=JM20K_1904-jpg-{z}-{x}-{y}'
      }, {
        name: '1/5000像片基本圖65年',
        url: 'https://wmts.nlsc.gov.tw/wmts/TOPO05KPHOTO_065/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '1/5000像片基本圖66年',
        url: 'https://wmts.nlsc.gov.tw/wmts/TOPO05KPHOTO_066/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '1/5000像片基本圖67年',
        url: 'https://wmts.nlsc.gov.tw/wmts/TOPO05KPHOTO_067/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '1/5000像片基本圖68年',
        url: 'https://wmts.nlsc.gov.tw/wmts/TOPO05KPHOTO_068/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '1/5000像片基本圖69年',
        url: 'https://wmts.nlsc.gov.tw/wmts/TOPO05KPHOTO_069/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      }, {
        name: '1/5000像片基本圖70年',
        url: 'https://wmts.nlsc.gov.tw/wmts/TOPO05KPHOTO_070/default/GoogleMapsCompatible/{z}/{y}/{x}.png'
      },
    ]
    const result = basemaps.map(({ name, url }) => {
      const instance = new TileLayer(url, {
        maxZoom: 20
      })
      const layer = new JSDCLayer({
          id: `${name}_${uniqueId('basemap')}`,
          description: {
            name: name,
            type: 'image'
          }
      })
      layer.setInstance(instance)
      return layer
    })
    return result
  }
}