import React from 'react'
import './AboutWalkDialogContent.scss'
import logoFromWeb from '../../../assets/header-logo.svg'

export interface IAboutWalkDialogContentProps {
  imgSrc: string
  title: string
  subtitle: string
  content: string
}

const AboutWalkDialogContent: React.FC<IAboutWalkDialogContentProps> = ({
  imgSrc,
  title,
  subtitle,
  content
}) => {
  
  return (
    <div className="dui-AboutWalkDialogContent">
      <div className="dui-AboutWalkDialogContent-img">
        <img src={imgSrc} alt={title} />
      </div>
      <div className="dui-AboutWalkDialogContent-title">
        <span>{title}</span>
        <span>{subtitle}</span>
      </div>
      <div className="dui-AboutWalkDialogContent-content" dangerouslySetInnerHTML={{ __html: content }}>
      </div>
      <a href="https://oldtree.life/" target="_blank"><img style={{ margin: '20px 0', padding: '0 20px' }} src={logoFromWeb}/></a>
    </div>
  )
}
AboutWalkDialogContent.displayName = 'AboutWalkDialogContent'
export default AboutWalkDialogContent
